$MOBILE_SCREEN_WIDTH: 420px;

@import url("https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&display=swap");
@font-face {
  font-family: "LinoTypeLight";
  src: url("../fonts/LinotypeUnivers-CondLight.woff2") format("woff2"); /* Super Modern Browsers */
}
@font-face {
  font-family: "LinoTypeBold";
  src: url("../fonts/LinotypeUnivers-CondBold.woff2") format("woff2"); /* Super Modern Browsers */
}
html {
  background-color: #ffffff;
  margin: 0;
}

body {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.4rem;
  padding-left: 2rem;
}

form button:hover {
  cursor: pointer;
}
form button:active,
form button:focus {
  outline: none;
}

.bold-text {
  font-family: "LinoTypeBold" !important;
}

.project-list-page,
.profile-page {
  position: absolute;
  width: 100vw;
  top: 0;
  padding-top: 100px;
}

.project-list-page {
  @media screen and (max-width: $MOBILE_SCREEN_WIDTH) {
    padding-top: 57px;
  }
}

.grid-container {
  z-index: 3;
}

.contact-page {
  position: absolute;
  top: 0;
  padding-top: 100px;
  width: 65vw;
}

.careers-page-parent {
  width: 100vw;
  position: absolute;
  top: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  font-size: 16.5px;

  @media screen and (max-width: 500px) {
    padding-top: 20px;
  }
}

.careers-page {
  text-align: left;
  width: 61%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
  line-height: 30px;

  li {
    list-style-position: inside;
  }

  @media screen and (max-width: 500px) {
    margin-right: 30px;
    margin-left: 30px;
    width: unset;
    img {
      width: 100%;
    }
  }
}

//ACCORDION UI
.accordion {
  margin: 2rem auto;
}

.panel {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 18px;
  border: 0.2px solid black;
  background-color: white;
}

.active {
  border: none;
}

.active,
.panel:hover {
  background-color: #c6c6bd;
}

.panel:after {
  content: "\002B";
  color: #777;
  font-weight: 400;
  float: right;
  margin-left: 10px;
  padding: 15px 10px;
}

.active:after {
  content: "\2212"; /* Unicode character for "minus" sign (-) */
}

.panel-title {
  text-transform: uppercase;
  color: #000;
  font-weight: 400;
  padding: 15px 10px;
}

.panel:not(:last-child) {
  margin: 0.5rem 0rem;
}

.menubar {
  display: block;
  height: 100px;
  text-align: center;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
  overflow-y: hidden;
}

#menutitle {
  position: absolute;
  width: auto;
  height: 45px;
  top: 51px;
  right: 90px;
  float: right;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  color: #ffffff;
  letter-spacing: 2px;
  font-size: 18px;
  pointer-events: none;
  transition: opacity 300ms ease-in-out;
  -moz-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  background: none;
  border: none;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}

.menubar-sitetitle {
  position: absolute;
  top: 58px;
  left: 100px;
  float: left;
  width: 50%;
}

.sitetitle-container {
  float: left;
  text-align: center;
}

.site-link {
  color: #ffffff;
}

.site-link:hover {
  color: #ffffff;
}

.menubar-sitetitle h1 {
  font-size: 22px;
  letter-spacing: 2px;
}

.menubar-sitetitle h5 {
  font-size: 11px;
  letter-spacing: 3px;
}

.menuclicker {
  content: "";
  height: 90px;
  width: 135px;
  cursor: pointer;
  z-index: 9;
  float: right;
}

#menu {
  position: fixed;
  top: 0;
  right: -40vw;
  height: 100vh;
  width: 40vw;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  z-index: 2;
}

.menu-box-shadow {
  /*
  box-shadow: -10px 0px 15px 1px rgba(255,255,255,0.7);
  -webkit-box-shadow: -10px 0px 15px 1px rgba(255,255,255,0.7);
  -moz-box-shadow: -10px 0px 15px 1px rgba(255,255,255,0.7);
  box-shadow: -10px 0px 15px 1px rgba(255,255,255,0.7);
  */
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.05);
}

#menu .menu-level-1 {
  margin-top: 50%;
  padding: 0px;
  color: #ffffff;
  list-style-type: none;
  text-align: left;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

#menu li:last-child {
  border-bottom: none;
}

.menu-level-1 li {
  padding: 5px 0 5px 5px;
  font-size: 1.2em;
  text-indent: 0;
  padding-left: 0;
}

.menu-level-1 ul {
  list-style-type: none;
  text-indent: 0;
  padding-left: 0;
}

.menu-level-2 li a {
  font-size: 12px;
  margin-left: 40px;
}

.menu-level-2 li {
  font-size: 0.8em;
}

#menu-indicator {
  position: absolute;
  top: 50%;
  width: 0;
  height: 1px;
  background: #ffffff;
  -webkit-transition: width 0.5s; /* For Safari 3.1 to 6.0 */
  transition: width 0.5s;
  left: 0;
}

li a {
  text-decoration: none;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

li a:hover {
  color: #d9d9d9;
}

#menu.isNotOpen {
  -webkit-transform: none;
  transform: none;
}

#closemenu {
  position: absolute;
  right: 100px;
  top: 50px;
  width: 32px;
  height: 32px;
  opacity: 1;
  background: none;
  border: none;
}
#closemenu:active,
#closemenu:focus,
#closemenu:hover {
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
}

#closemenu:hover {
  opacity: 1;
}
#closemenu:before,
#closemenu:after {
  position: absolute;
  left: 25px;
  content: " ";
  height: 33px;
  width: 1px;
}
.close-menu-white:before,
.close-menu-white:after {
  background-color: #ffffff;
}
.close-menu-black:before,
.close-menu-black:after {
  background-color: #000000;
}
#closemenu:before {
  transform: rotate(45deg);
}
#closemenu:after {
  transform: rotate(-45deg);
}

.menu-p {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1em;
  cursor: default;
}

#logo {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

#project-carousel {
  opacity: 0;
  -moz-transition: all 2s ease-in;
  -o-transition: all 2s ease-in;
  -webkit-transition: all 2s ease-in;
  transition: all 2s ease-in;
}

/* SPLASH PAGE */

.splashpage {
  height: 100vh;
  text-align: center;
}

#splashpage-title {
  color: #000000;
  position: absolute;
  top: 88%;
  z-index: 101;
  width: 100%;
  text-align: center;
  font-size: 22px;
  letter-spacing: 3.5px;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
}

#splashpage-tagline {
  font-family: "LinoTypeLight";
  position: absolute;
  top: 90%;
  text-align: center;
  width: 100%;
  letter-spacing: 4px;
  font-size: 11px;
}

h1#site-title,
h1#splashpage-title {
  font-weight: 200 !important;
  font-family: "LinoTypeLight" !important;
  letter-spacing: 3.5px;
}
h1#default-title {
  font-weight: 200;
  font-family: "LinoTypeBold" !important;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 5px;
  letter-spacing: 3.5px;
  margin: 20px 0px;
}

#site-tagline {
  letter-spacing: 4px;
}

#splashpage-top {
  position: absolute;
  top: 0;
  width: 100%;
  background: #ffffff;
  z-index: 100;
  height: 50vh;
}

#splashpage-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  z-index: 100;
  height: 50vh;
}

.fade-away {
  animation: fade-away 3s;
  -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
  -moz-animation-fill-mode: forwards; /* FF 5+ */
  -o-animation-fill-mode: forwards; /* Not implemented yet */
  -ms-animation-fill-mode: forwards; /* IE 10+ */
  animation-fill-mode: forwards;
}

@keyframes fade-away {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.splashpage-line-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  border-bottom: 0.1em solid #000000;
  width: 0%;
  text-align: center;
  animation: draw-line 1s forwards;
  z-index: 101;
}

.splashpage-fade-animation {
  -webkit-animation: fade-in 2s ease-in-out;
  animation: fade-in 2s ease-in-out;
}

.slide-in-blurred-top {
  -webkit-animation: slide-in-blurred-top 1.5s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-top 1.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.height-out-animation {
  -webkit-animation: height-out 1.7s forwards;
  animation: height-out 1.7s forwards;
}

.transparent-animation {
  -webkit-animation: color-fade 0.2s forwards;
  animation: color-fade 0.2s forwards;
}

/* HOMEPAGE SLIDESHOW STYLINGS */

//For HomePageSlideShow Basic can be deleted if decide to go with kenburn
.slideshow-homepage-videos {
  position: absolute;
  transition: opacity 0.7s ease-in;
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.slideshow-homepage-videos + .slideshow-homepage-videos {
  opacity: 0;
}

/* KEN BURNS EFFECT */
.slideshow {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  z-index: 1;
}

.slideshow-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  -webkit-animation-name: kenburns;
  animation-name: kenburns;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 32s;
  animation-duration: 32s;
  opacity: 1;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.slideshow-image:nth-child(1) {
  -webkit-animation-name: kenburns-1;
  animation-name: kenburns-1;
  z-index: 7;
}

.slideshow-image:nth-child(2) {
  -webkit-animation-name: kenburns-2;
  animation-name: kenburns-2;
  z-index: 6;
}

.slideshow-image:nth-child(3) {
  -webkit-animation-name: kenburns-3;
  animation-name: kenburns-3;
  z-index: 5;
}

.slideshow-image:nth-child(4) {
  -webkit-animation-name: kenburns-4;
  animation-name: kenburns-4;
  z-index: 4;
}
.slideshow-image:nth-child(5) {
  -webkit-animation-name: kenburns-1;
  animation-name: kenburns-1;
  z-index: 3;
}

.slideshow-image:nth-child(6) {
  -webkit-animation-name: kenburns-2;
  animation-name: kenburns-2;
  z-index: 2;
}

.slideshow-image:nth-child(7) {
  -webkit-animation-name: kenburns-3;
  animation-name: kenburns-3;
  z-index: 1;
}

.slideshow-image:nth-child(8) {
  -webkit-animation-name: kenburns-4;
  animation-name: kenburns-4;
  z-index: 0;
}

@-webkit-keyframes kenburns-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  1.5625% {
    opacity: 1;
  }
  23.4375% {
    opacity: 1;
  }
  26.5625% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  98.4375% {
    opacity: 0;
    -webkit-transform: scale(1.21176);
    transform: scale(1.21176);
  }
  100% {
    opacity: 1;
  }
}
@keyframes kenburns-1 {
  0% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  1.5625% {
    opacity: 1;
  }
  23.4375% {
    opacity: 1;
  }
  26.5625% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  98.4375% {
    opacity: 0;
    -webkit-transform: scale(1.21176);
    transform: scale(1.21176);
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes kenburns-2 {
  23.4375% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  26.5625% {
    opacity: 1;
  }
  48.4375% {
    opacity: 1;
  }
  51.5625% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes kenburns-2 {
  23.4375% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  26.5625% {
    opacity: 1;
  }
  48.4375% {
    opacity: 1;
  }
  51.5625% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@-webkit-keyframes kenburns-3 {
  48.4375% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  51.5625% {
    opacity: 1;
  }
  73.4375% {
    opacity: 1;
  }
  76.5625% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@keyframes kenburns-3 {
  48.4375% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  51.5625% {
    opacity: 1;
  }
  73.4375% {
    opacity: 1;
  }
  76.5625% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@-webkit-keyframes kenburns-4 {
  73.4375% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  76.5625% {
    opacity: 1;
  }
  98.4375% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes kenburns-4 {
  73.4375% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  76.5625% {
    opacity: 1;
  }
  98.4375% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.slideshow-image-fadeinout {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  animation-name: fadeInOut;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 30s;
  opacity: 1;
}

.slideshow-image-fadeinout:nth-child(1) {
  animation-name: fadeInOut-1;
  z-index: 7;
}

.slideshow-image-fadeinout:nth-child(2) {
  animation-name: fadeInOut-2;
  z-index: 6;
}

.slideshow-image-fadeinout:nth-child(3) {
  animation-name: fadeInOut-3;
  z-index: 5;
}

.slideshow-image-fadeinout:nth-child(4) {
  animation-name: fadeInOut-4;
  z-index: 4;
}

.slideshow-image-fadeinout:nth-child(5) {
  animation-name: fadeInOut-1;
  z-index: 3;
}

.slideshow-image-fadeinout:nth-child(6) {
  animation-name: fadeInOut-2;
  z-index: 2;
}

.slideshow-image-fadeinout:nth-child(7) {
  animation-name: fadeInOut-3;
  z-index: 1;
}

.slideshow-image-fadeinout:nth-child(8) {
  animation-name: fadeInOut-4;
  z-index: 0;
}

@keyframes fadeInOut-1 {
  0% {
    opacity: 1;
  }
  1.5625% {
    opacity: 1;
  }
  23.4375% {
    opacity: 1;
  }
  26.5625% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
  98.4375% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInOut-2 {
  23.4375% {
    opacity: 1;
  }
  26.5625% {
    opacity: 1;
  }
  48.4375% {
    opacity: 1;
  }
  51.5625% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut-3 {
  48.4375% {
    opacity: 1;
  }
  51.5625% {
    opacity: 1;
  }
  73.4375% {
    opacity: 1;
  }
  76.5625% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut-4 {
  73.4375% {
    opacity: 1;
  }
  76.5625% {
    opacity: 1;
  }
  98.4375% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* FADE IN ANIMATION */

/* Safari 4.0 - 8.0 */
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* HEIGHT OUT ANIMATION */

/* Safari 4.0 - 8.0 */
@-webkit-keyframes height-out {
  from {
    height: 50vh;
  }
  to {
    height: 0;
  }
}

/* TRANSPARENT ANIMATION */

/* Safari 4.0 - 8.0 */
@-webkit-keyframes color-fade {
  to {
    color: rgba(0, 0, 0, 0);
  }
}

/* Standard syntax */
@keyframes color-fade {
  to {
    color: rgba(0, 0, 0, 0);
  }
}

/* LINE ANIMATION */

@keyframes draw-line {
  from {
    left: 50%;
    width: 0%;
  }
  to {
    left: 0%;
    width: 100vw;
  }
}

/* SLIDE IN ANIMATION */

/* ----------------------------------------------
 * Generated by Animista on 2019-5-6 22:11:22
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
    transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.page-fade-out {
  animation: fade-away 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  position: absolute;
  left: 0 !important;
}

//For project slideshow exit, prevent container from jumping down in desktop to account for header container
.full-screen {
  top: 0 !important;
}

/* PROJECTS PAGE */

//Category buttons
.category-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: normal;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  margin: 40px 0px;
  text-align: center;
  @media screen and (max-width: $MOBILE_SCREEN_WIDTH) {
    margin: 0px 0px;
    font-size: 17px;
  }
}

.category-button {
  display: block;
  flex-grow: 0.05;
  flex-shrink: 0.05;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  @media screen and (max-width: $MOBILE_SCREEN_WIDTH) {
    margin: 10px 10px;
  }
  &:hover {
    text-shadow: 0 0 0.01px black, 0 0 0.01px black, 0 0 0.01px black;
  }
  & .highlight {
    text-shadow: 0 0 0.01px black, 0 0 0.01px black, 0 0 0.01px black;
  }
}

//Projects Display
.grid-container-projects {
  @media (max-width: 759px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }

  @media (min-width: 760px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
//Columns
.grid-container-projects > * {
  padding: 1rem;
}

.column-left {
  margin-left: auto;
  @media (max-width: 759px) {
    margin: 0;
  }
}

/**
 * Project Card
**/
.project-card,
.projectCard {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  transition: 1s;
  -webkit-transition: 1s;
  @media (max-width: 759px) {
    margin: 0px auto 30px auto;
  }

  &.horizontal {
    width: 600px;
    height: 480px;

    @media (max-width: 1180px) {
      width: 450px;
      height: 350px;
    }

    @media (max-width: 950px) {
      width: 400px;
      height: 300px;
    }

    @media (max-width: 850px) {
      width: 350px;
      height: 250px;
    }
  }

  &.vertical {
    width: 480px;
    height: 600px;

    @media (max-width: 1180px) {
      width: 350px;
      height: 450px;
    }

    @media (max-width: 950px) {
      width: 300px;
      height: 400px;
    }

    @media (max-width: 850px) {
      width: 250px;
      height: 300px;
    }
  }
}

//For images that are smaller than the defined width
.img-vertical {
  min-width: 350px;
  @media (max-width: 759px) {
    min-width: 250px;
  }
}

.img-vertical,
.img-horizontal {
  object-fit: cover;
  transition: 1.5s;
  height: 100%;
  width: 100%;

  &:hover {
    transform: scale(1.2);
  }
}

video.img-vertical,
video.img-horizontal {
  &:hover {
    transform: none;
  }
}

.project-details {
  opacity: 0;
  transition: 0.7s;
  -webkit-transition: 0.7s;
}

.project-link {
  &.left {
    float: right;
    @media (max-width: 759px) {
      float: none;
    }
  }
}

.project-title-line {
  border-bottom: solid 1px black;
  border-top-width: 0px;
  animation-name: line_animation;
  animation-duration: 1s;
  animation-timing-function: linear;
  margin: 0;
}

@keyframes line_animation {
  from {
    width: 0%;
  }
  to {
    width: 10%;
  }
}

/*
.carousel {
  max-height: 154px;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  max-height: 154px;
}
*/
.mouse-prev {
  cursor: url("../icons/back-blk3.png"), auto;
  width: 64px;
}

.mouse-next {
  cursor: url("../icons/next-blk3.png"), auto;
  width: 64px;
}

.mouse-end {
  cursor: url("../icons/end3.png"), auto;
  width: 64px;
}

.carousel .control-dots {
  position: absolute !important;
  bottom: 15 !important;
  width: 100% !important;
  text-align: right !important;
  margin-left: -100px !important;
  margin-bottom: 40px !important;
  opacity: 0;
  transition: 2s;
  -webkit-transition: 2s;
  cursor: default !important;
}

.dot {
  pointer-events: none;
  cursor: default;
}

.project {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: absolute;
  overflow: hidden;
  // transition: 1.5s;
}

.animate-slide-img-container {
  transition: 1.5s;
}

.carousel-right-desc img,
.carousel-left-even img,
.carousel-right-even img,
.carousel-left-odd img,
.carousel-right-odd img {
  object-fit: cover;
}

.slide-img {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}

.project-link:hover {
  cursor: pointer;
}

.fullscreen {
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  display: initial !important;
  align-items: initial !important;
  justify-content: initial !important;
  max-height: initial !important;
  overflow: initial !important;
}

.profile-card-title,
.project-card-title {
  text-transform: uppercase;
  line-height: 22px;
  padding-top: 20px;
  width: 300px;
}

.profile-card-title {
  font-size: 14px !important;
  color: #000;
  text-align: center;
  margin-bottom: 0px !important;
  letter-spacing: 2px;
}

.project-card-title {
  font-size: 25px !important;
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  color: white;
  position: absolute;
  text-align: left;
  margin-left: 10px;
  bottom: 0;
  margin-bottom: 15px;
  @media screen and (max-width: $MOBILE_SCREEN_WIDTH) {
    font-size: 20px !important;
  }
}

.project-card-title-gradient {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, transparent 80%, black 100%);
}

.profile-card-desc,
.project-card-desc {
  font-size: 10px !important;
  color: #000;
  text-transform: uppercase;
  line-height: 15px;
  padding-top: 0px;
  letter-spacing: 2px;
  text-align: center;
  width: 300px;
}

.project-details {
  width: 100%;
  position: absolute;
  bottom: 0;
  color: #ffffff;
  line-height: 150px;
  font-size: 45px;
  letter-spacing: 2px;
  font-weight: 200;
  text-transform: uppercase;
}

#project-detail-container {
  bottom: 15px;
}

.project-details .project-title {
  padding-left: 180px;
  bottom: -15px;
  position: absolute;
  font-weight: 200;
}

.project-details .project-title-line {
  position: absolute;
  width: 160px;
  height: 55px;
  border-top: 1px solid white;
  border-bottom: 0px white;
  border-right: 0px white;
  border-left: 0px white;
  bottom: 0;
}

.delay-1 {
  animation-delay: 0s;
  animation-duration: 1s;
}
.delay-2 {
  animation-delay: 0.2s;
  animation-duration: 1s;
}
.delay-3 {
  animation-delay: 0.4s;
  animation-duration: 1;
}
.delay-4 {
  animation-delay: 0.6s;
  animation-duration: 1s;
}
.delay-5 {
  animation-delay: 0.8s;
  animation-duration: 1s;
}
.delay-6 {
  animation-delay: 1s;
  animation-duration: 1s;
}
.delay-7 {
  animation-delay: 1.2s;
  animation-duration: 1s;
}
.delay-8 {
  animation-delay: 1.4s;
  animation-duration: 1s;
}
.delay-9 {
  animation-delay: 1.6s;
  animation-duration: 1s;
}
.delay-10 {
  animation-delay: 1.8s;
  animation-duration: 1s;
}
.delay-11 {
  animation-delay: 2s;
  animation-duration: 1s;
}
.delay-12 {
  animation-delay: 2.2s;
  animation-duration: 1s;
}
.delay-13 {
  animation-delay: 2.4s;
  animation-duration: 1s;
}
.delay-14 {
  animation-delay: 2.6s;
  animation-duration: 1s;
}
.delay-15 {
  animation-delay: 2.8s;
  animation-duration: 1s;
}

/**
 * Employee Profile 
**/

.employee-content {
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 60px;
  padding-bottom: 60px;
  // min-height: 100vh;
}

.grid-container {
  display: grid;
  align-content: center;
  justify-content: space-around;
  /*grid-template-columns: auto auto auto;*/
  grid-template-columns: repeat(auto-fill, 300px);
  max-width: 1190px;
  margin-left: auto;
  margin-right: auto;
}

.profile-card,
.profileCard {
  background: #ffffff;
  position: relative;
  top: 0;
  transition: all 0.2s;
  padding-bottom: 50px;
}

.profile-card:hover,
.profileCard:hover {
  top: -20px;
}

.profile-content {
  text-align: center;
  width: 61%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 60px;
  line-height: 30px;
  max-width: 1200px;

  @media screen and (min-width: 1600px) {
    p {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 2400px) {
    p {
      font-size: 20px;
    }
  }
}

.profile-card-img {
  width: 300px;
  height: 154px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: 0.7s;
  -webkit-transition: 0.7s;
}

.profile-link p {
  background-color: #ffffff;
}

.modal-footer {
  width: 100%;
  background: #e1e2e0;
  margin-top: -5px;
  min-height: 50px;
  padding: 15px 10px;
  letter-spacing: 1px;
  font-weight: 300;
  text-align: center;
}

.modal-bio,
.modal-excerpt {
  font-size: 16px;
  text-align: left;
}

.modal-title {
  line-height: 40px;
  text-align: center;
}

.employee-name {
  font-weight: 500;
}

.contact-page {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 110px;
  height: 500px;
}

.contact-container {
  width: 50%;
  float: left;
  padding: 30px;
}

.contact-info,
.form-container {
  width: 100%;
  float: left;
}

.form-container {
  height: 100%;
}

.address-container,
.phone-email-container {
  width: 50%;
  float: left;
}

.address-container span,
.phone-email-container span {
  line-height: 35px;
  letter-spacing: 1.5px;
}

.phone-email-container {
  text-align: right;
}

.phone-email-header,
.address-header {
  font-weight: 800;
}

.form-input,
.form-button {
  width: 100%;
}

.form-input {
  width: 100%;
  height: 35px;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-left: 10px;
  border: 1px solid black;
}

.form-button {
  height: 40px;
  border: none;
  background: #c6c6bd;
  color: black;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.form-textarea {
  resize: none;
  width: 100%;
  height: 80px;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid black;
}

.form-header {
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 40px;
}

input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: 0 0 10px #cecece;
}

.loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.carousel .slide {
  background: #ffffff !important;
}

.padded-multiline {
  word-break: break-word;
}

textarea,
input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
  border-radius: 0;
}

input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none;
  border-radius: 0;
}

.res {
  text-align: center;
  line-height: 40px;
}

.footer-cp {
  font-size: 11px;
  position: absolute;
  bottom: 0;
  padding-left: 100px;
  padding-bottom: 25px;
}

/* RESPONSIVE */
@media screen and (max-width: $MOBILE_SCREEN_WIDTH) {
  .menubar span,
  #site-title {
    font-size: 1em;
  }
}

.mobile-project-title {
  text-align: center;
  font-family: "LinoTypeBold";
  letter-spacing: 3.5px;
  font-size: 1.3rem;

  -webkit-animation: fade-in 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fade-in 2s; /* Firefox < 16 */
  -ms-animation: fade-in 2s; /* Internet Explorer */
  -o-animation: fade-in 2s; /* Opera < 12.1 */
  animation: fade-in 2s;
}

.mobile-project-content-container {
  padding-bottom: 20px;
}

.mobile-project-content-container img {
  width: 100vw;
}

.mobile-project-desc {
  margin: 20px 0px;
  padding: 15px;
}

//Lazy Loading Image Containers
.image-placeholder-container {
  height: 900px;
  width: 100vw;
  text-align: center;
}

.image-placeholder-loading {
  width: 30vw;
  background-image: url(/loading.gif);
  background-size: cover;
  background-position: center center;
  display: inline-block;
}

/* ----------- iPad Pro ----------- */
/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .mobile-project-title {
    width: 100%;
    text-align: center;
    z-index: 5;
    pointer-events: none;
    font-size: 1.8rem;
  }
  .mobile-project-desc {
    font-size: 1.3rem;
  }
  .carousel .slide img {
    width: auto;
    max-height: 1000px;
    // height: 100vh;
    // object-fit: contain;
  }
  .carousel .control-dots {
    text-align: center !important;
    margin-left: 0 !important;
  }
  .mobile-project-container {
    height: 90vh;
    position: absolute;
    top: 150px;
  }
  .contact-page {
    margin-top: 0;
    top: 100px;
  }
  .menubar {
    width: 100vw;
    overflow: hidden;
    // height: 100vh;
    position: fixed;
    background-color: white;
  }
  .menubar-sitetitle {
    top: 40px;
  }
  //Menu Icon
  #menutitle {
    top: 35px;
  }

  .category-dropdown-container {
    position: sticky;
    z-index: 5;
    top: 100px;
  }
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .mobile-project-title {
    width: 100%;
    text-align: center;
    z-index: 5;
    pointer-events: none;
  }

  .mobile-project-container {
    height: 90vh;
    position: absolute;
    top: 80px;
  }
  .valign {
    position: relative;
    top: 25%;
    transform: translateY(-50%);
  }

  .carousel .control-dots {
    position: absolute !important;
    bottom: -115px !important;
    width: 100% !important;
    text-align: center !important;
    margin-left: 0px !important;
    margin-bottom: 140px !important;
  }
  .mobile-project-title p {
    font-size: 20px;
  }

  #splashpage-tagline {
    margin-top: 5px;
    top: 88%;
  }
  #splashpage-title {
    top: 80%;
  }
  #splashpage-top {
    z-index: 100;
    height: 50%;
  }
  #splashpage-bottom {
    z-index: 99;
    height: 50%;
  }
  .splashpage-line-animation {
    border-bottom: 0.08em solid #000000;
  }
  #menu {
    width: 40vw;
    right: -40vw;
  }
  .project-list-page .grid-container,
  .contact-page {
    position: absolute;
    top: 100px;
    width: 100%;
    z-index: 10;
  }
  .profile-page {
    position: absolute;
    top: 80px;
  }
  #project-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
  }
  #project {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 100px;
  }
  .content {
    margin: 0;
  }
  .contact-page {
    width: 100%;
    margin: 0;
    top: 0;
  }
  .menubar {
    width: 100vw;
    overflow: hidden;
    // height: 100vh;
    position: fixed;
    background-color: white;
  }
  #closemenu {
    right: 30px;
    top: 14px;
  }
  #closemenu:before,
  #closemenu:after {
    height: 17px;
    width: 1px;
  }
  .menubar-sitetitle {
    top: 35px;
    width: 60%;
  }
  .menubar-sitetitle h1 {
    font-size: 14px;
  }
  .menubar-sitetitle h5 {
    font-size: 8px;
  }
  .menubar-sitetitle {
    left: 20px;
  }
  #menutitle {
    right: 30px;
    font-size: 14px;
    top: 20px;
    width: auto;
  }
  .grid-container {
    // grid-template-columns: repeat(auto-fill, 300px);
    grid-template-columns: 50%;
  }
  .profile-content {
    width: 80%;
  }
  .project-details .project-title {
    padding-left: 20px;
    width: 100%;
    font-weight: 200;
    font-size: 18px;
  }
  .project-details {
    width: 100%;
    line-height: 20px;
  }
  #project-detail-container {
    bottom: 135px;
    width: 100%;
  }
  .address-container,
  .phone-email-container {
    margin-bottom: 40px;
    font-size: 12px;
  }
  .contact-container {
    width: 100%;
  }
  #menu .menu-level-1 {
    width: 110px;
  }
  .loader-container {
    margin-top: -23px;
    margin-left: -23px;
  }
  .staff-modal {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
  }
  .category-dropdown-container {
    position: sticky;
    z-index: 5;
    top: 90px;
  }
}

@media only screen and (min-width: 1023px) and (max-height: 1025px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .contact-page {
    top: 0;
    padding-top: 125px;
  }
  .category-dropdown-container {
    position: sticky;
    z-index: 5;
    top: 90px;
  }
}

//MOBILE DEVICE
@media only screen and (min-device-width: 300px) and (max-device-width: 374px) {
  .menubar {
    width: 100vw;
    overflow: hidden;
  }
  #closemenu {
    right: 30px;
    top: 14px;
  }
  #closemenu:before,
  #closemenu:after {
    height: 17px;
    width: 1px;
  }
  .menubar-sitetitle {
    top: 17.5px;
    width: 60%;
  }
  .menubar-sitetitle h1 {
    font-size: 8px;
  }
  .menubar-sitetitle h5 {
    font-size: 8px;
  }
  .menubar-sitetitle {
    left: 20px;
  }
  #menutitle {
    right: 10px;
    font-size: 10px;
    top: 5px;
    width: auto;
  }
  #menu {
    width: 100vw;
    right: -100vw;
  }
  .menubar span,
  #site-title {
    font-size: 0.95em;
  }
  .contact-page {
    width: 80%;
    margin-left: 10%;
    margin-right: auto;
  }
  .contact-container {
    width: 100%;
    float: left;
    padding: 0px;
  }
  .form-container {
    margin-bottom: 20px;
  }
}
//MOBILE DEVICE
@media only screen and (min-device-width: 375px) and (max-device-width: 768px) {
  .mobile-project-title {
    width: 100%;
    text-align: center;
    z-index: 5;
    pointer-events: none;
  }

  .mobile-project-desc {
    font-size: 1.1rem;
  }

  .mobile-project-container {
    height: 90vh;
    position: absolute;
    top: 80px;
  }
  .valign {
    position: relative;
    top: 25%;
    transform: translateY(-30%);
  }

  .carousel .control-dots {
    position: absolute !important;
    bottom: -115px !important;
    width: 100% !important;
    text-align: center !important;
    margin-left: 0px !important;
    margin-bottom: 140px !important;
  }
  .mobile-project-title p {
    font-size: 20px;
  }
  #splashpage-tagline {
    margin-top: 5px;
    top: 88%;
  }
  #splashpage-title {
    top: 80%;
  }
  #splashpage-top {
    z-index: 100;
    height: 50%;
  }
  #splashpage-bottom {
    z-index: 99;
    height: 50%;
  }
  .splashpage-line-animation {
    border-bottom: 0.08em solid #000000;
  }
  #menu {
    width: 100vw;
    right: -100vw;
  }
  .project-list-page .grid-container-projects,
  .contact-page {
    top: 100px;
    width: 100%;
  }
  .profile-page {
    position: absolute;
  }
  #project-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
  }
  #project {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 100px;
  }
  .content {
    margin: 0;
  }
  .contact-page {
    width: 100%;
    margin: 0;
    padding-top: 0;
  }
  .menubar {
    padding-top: 15px;
    margin-bottom: 0px;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    height: 57px;
    z-index: 2;
  }

  .menuclicker {
    height: 45px;
  }

  #closemenu {
    right: 30px;
    top: 22px;
  }
  #closemenu:before,
  #closemenu:after {
    height: 17px;
    width: 1px;
  }
  .menubar-sitetitle {
    top: 17.5px;
    width: 60%;
  }
  .menubar-sitetitle h1 {
    font-size: 14px;
  }
  .menubar-sitetitle h5 {
    font-size: 8px;
  }
  .menubar-sitetitle {
    left: 20px;
  }
  #menutitle {
    right: 10px;
    font-size: 14px;
    top: 5px;
    width: auto;
  }
  .grid-container {
    grid-template-columns: repeat(auto-fill, 300px);
  }
  .profile-content {
    width: 80%;
  }
  .project-details .project-title {
    padding-left: 20px;
    width: 100%;
    font-weight: 200;
    font-size: 18px;
  }
  .project-details {
    width: 100%;
    line-height: 20px;
  }
  #project-detail-container {
    bottom: 135px;
    width: 100%;
  }
  .address-container,
  .phone-email-container {
    margin-bottom: 40px;
    font-size: 12px;
  }
  .contact-container {
    width: 100%;
  }
  #menu .menu-level-1 {
    width: 110px;
  }
  .loader-container {
    margin-top: -23px;
    margin-left: -23px;
  }
  .staff-modal {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
  }

  .staff-modal {
    height: 100vh;
  }

  .category-dropdown-container {
    position: sticky;
    z-index: 5;
    top: 57px;
  }

  @media (orientation: landscape) {
    #menu .menu-level-1 {
      margin-top: 10%;
    }
    // .menubar {
    //   height: 150px;
    // }
    .mobile-project-container {
      height: 100vh;
      top: 100px;
      width: 100%;
    }
    .mobile-project-title {
      top: -85px;
      left: 50%;
      width: auto;
      text-align: center;
      font-size: 16px !important;
      pointer-events: none;
    }
    .mobile-project-title p {
      font-size: 18px;
    }
  }
}

.header-whitebkgd {
  background-color: white;
}

.header-transparentbkgd {
  background-color: transparent;
}

.publication-card {
  a {
    color: black;
  }
}

.publication-card:hover {
  top: -20px;
}

.publication-card-img {
  width: 300px;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: 0.7s;
  -webkit-transition: 0.7s;
}

.publication-card-text {
  text-transform: uppercase;
  text-align: center;
  font-family: "Oswald", sans-serif;
}

.publication-description {
  margin: 8px;
  font-weight: 400;
  font-size: 18px;
}

.publication-date {
  font-weight: 200;
  font-size: 14px;
}

//Dropdown
.dropdown {
  margin: 0 auto;
  width: 100vw;
  background: #c6c6bd;
  justify-content: center;
  display: inline-grid;
}

.dropdown__text {
  color: black;
  padding: 10px 16px;
  cursor: pointer;
  height: auto;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100vw;
}

// .dropdown__text:hover {
//   color: black;
//   background: #c6c6bd;
// }

.dropdown__text:after {
  content: "+";
  transition: all 0.2s;
  float: right;
  margin-top: -8px;
  margin-bottom: -6px;
  font-size: 25px;
}

.dropdown.active .dropdown__text:after {
  margin-top: -12px;
  content: "-";
  font-size: 25px;
}

.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.3s ease;
  text-align: center;
  text-transform: uppercase;
}

.dropdown.active .dropdown__items {
  visibility: visible;
  border-top: 1px solid #c6c6bd;
  height: auto;
  max-height: 290px;
  opacity: 1;
  transition: max-height 0.3s, opacity 0.5s, visibility 0.5s ease;
}

.dropdown__item {
  cursor: pointer;
  padding: 4px 18px;
}

.dropdown__item:not(:last-child) {
  border-bottom: 0.5px solid #c6c6bd;
}

.dropdown__item:hover {
  color: black;
  background: #c6c6bd;
}
